import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CYCLE_COUNT_PLANS,
  GET_CYCLE_COUNT_PLAN, //22
  GET_WAREHOUSES,
  GET_INVENTORY,
  GET_USERS,
  GET_BIN_LOCATIONS,
  SEARCH_LOCATIONS,
  GET_PLAN_EXECUTIONS, //
} from "#queries";
import {
  SAVE_CYCLE_COUNT_PLAN,
  DELETE_CYCLE_COUNT_PLAN,
  EXECUTE_CYCLE_COUNT_PLAN,
  SAVE_SKU_BIN_MAPPINGS,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import moment from "moment-timezone";

const withCycleCountPlansLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const [selectedCycleCountPlan, setSelectedCycleCountPlan] = useState(null);
    const cycleCountPlansQuery = useQuery(GET_CYCLE_COUNT_PLANS);
    const auth = useContext(AuthContext);
    const [compareExecution, setCompareExecution] = useState({});
    const getCycleCountPlanQuery = useQuery(GET_CYCLE_COUNT_PLAN);
    const getCycleCountPlanQueryAdjustments = useQuery(GET_CYCLE_COUNT_PLAN);
    const saveCycleCountPlanQuery = useQuery(SAVE_CYCLE_COUNT_PLAN);
    const saveExecutionStockAdjustmentQuery = useQuery(SAVE_CYCLE_COUNT_PLAN);
    const deleteCycleCountPlanQuery = useQuery(DELETE_CYCLE_COUNT_PLAN);
    const searchLocationsQuery = useQuery(SEARCH_LOCATIONS);
    const [availableInventory, setAvailableInventory] = useState([]);
    const inventoryQuery = useQuery(GET_INVENTORY);
    const executeCycleCountPlanQuery = useQuery(EXECUTE_CYCLE_COUNT_PLAN);
    const saveSkuBinMappingsQuery = useQuery(SAVE_SKU_BIN_MAPPINGS);
    const [planToActivate, setPlanToActivate] = useState(null);
    const [itemsAvailableForAssignment, setItemsAvailableForAssignment] =
      useState({});
    const [binLocationOptions, setBinLocationOptions] = useState({});
    const [selectedPlanExecutions, setSelectedPlanExecutions] = useState(null);
    const [selectedDetailedExecution, setSelectedDetailedExecution] =
      useState(null);
    const [selectedSkusForAdjustment, setSelectedSkusForAdjusment] = useState(
      [],
    );
    const getPlanExecutionsQuery = useQuery(GET_PLAN_EXECUTIONS);
    const [binLocations, setBinLocations] = useState([]);
    const [selectedSpecificBins, setSelectedSpecificBins] = useState([]);
    const [printReport, setPrintReport] = useState(false);

    useEffect(() => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }
      }
    }, [auth]);

    useEffect(() => {
      cycleCountPlansQuery.fetchData();
    }, []);

    /**
     * Fetches bin location data based on a keyword using binary search.
     * It retrieves a paginated result set with a limit of 10 entries per page and starts from the first page.
     *
     * @function
     * @param {string} keyword - The keyword used for filtering the bin location data.
     */
    const onChangeBinSearch = (keyword) => {
      searchLocationsQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        code: keyword || undefined,
        warehouses: [selectedCycleCountPlan.warehouse].filter(Boolean),
        pagination: {
          limit: 10,
          offset: 0,
        },
      });
    };
    const debouncedBinLocationSearch = _.debounce(onChangeBinSearch, 500);

    const adjustSku = (skuItem, scannedSkus, availableQuantity, reason) => {
      if (reason) {
        return "COMPLETED";
      }
      if (scannedSkus === availableQuantity) {
        return "NO ADJUSTMENT NEEDED";
      }
      if (
        skuItem === undefined ||
        skuItem.length == 0 ||
        (scannedSkus > 0 && availableQuantity === 0)
      ) {
        return "NON EXISTENT LOCATION";
      }
      return "INVENTORY MISMATCH";
    };
    useEffect(() => {
      if (
        getPlanExecutionsQuery.data &&
        getCycleCountPlanQueryAdjustments.data
      ) {
        const executions = [];
        const systemValues = [];
        const executionData = [];
        let executionId;
        let executionStatus;
        const { warehouse } =
          getCycleCountPlanQueryAdjustments.data.cycleCountPlan;
        getCycleCountPlanQueryAdjustments.data.cycleCountPlan.executions.map(
          (e) => {
            executionStatus = e.status;

            if (!e?.stockAdjustments) return;
            e?.stockAdjustments?.map((a) => {
              executionData.push(
                ...a.adjustments.map((r) => ({
                  ...r,
                  exID: e.id,
                  reason: a.reason,
                })),
              );
            });
          },
        );
        getPlanExecutionsQuery.data.planExecutions.map((batch) => {
          executionId = batch.executionId;
          // executionStatus = batch.status;

          if (batch.workingList) {
            executions.push(
              ...batch.workingList.map((item) => ({
                ...item,
                // TODO: FIND ACTUAL USER WHO RAN THE CYCLE COUNT
                userName: "N/A",
              })),
            );
          }
          if (batch.items) {
            systemValues.push(
              ...batch.items.map((item) => ({
                ...item,
                userName: "N/A",
              })),
            );
          }
        });
        let dictionary = {};
        let statusHash = {};
        for (const execution of executions) {
          for (const skuItem of execution.skuItems) {
            const key = uniqueAdjustmentRow({
              ...skuItem,
              binLocation: execution.binLocation,
            });
            if (execution.status === "COMPLETED") {
              statusHash[execution.binLocation] = true;
            }
            dictionary[key] = {
              binLocation: execution.binLocation,
              sku: skuItem.sku,
              uom: skuItem.uom,
              nestedFormFactor: skuItem.nestedFormFactor,
              nestedFormFactorId: skuItem.nestedFormFactorId,
              identifier: skuItem.identifier,
              barcodeScanned: skuItem.barcodeScanned,
              scannedCount: skuItem.scannedSkus,
              systemCount: skuItem.availableQuantity || 0,
              timestamp: execution.timestamp,
              status: execution.status,
              customer: skuItem.customer,
              warehouse,
              userName: execution.userName,
              adjustmentStatus: adjustSku(
                skuItem,
                skuItem.scannedSkus,
                skuItem.availableQuantity || 0,
                executionData.find(
                  (r) =>
                    r.exID === executionId && uniqueAdjustmentRow(r) === key,
                )?.reason,
              ),
              reason:
                executionData.find(
                  (e) =>
                    e.exID === executionId && uniqueAdjustmentRow(e) === key,
                )?.reason || "N/A",
            };
          }
        }
        for (const systemValue of systemValues) {
          for (const skuItem of systemValue.skuItems) {
            const key = uniqueAdjustmentRow({
              ...skuItem,
              binLocation: systemValue.binLocation,
            });
            let isbinCompleted;
            if (statusHash[systemValue.binLocation]) {
              isbinCompleted = true;
            }
            let object = {
              binLocation: systemValue.binLocation,
              sku: skuItem.sku,
              uom: dictionary[key]?.uom,
              nestedFormFactor: dictionary[key]?.nestedFormFactor,
              nestedFormFactorId: dictionary[key]?.nestedFormFactorId,
              identifier: dictionary[key]?.identifier,
              barcodeScanned: dictionary[key]?.barcodeScanned,
              scannedCount: dictionary[key]?.scannedCount || 0,
              systemCount: skuItem.availableQuantity,
              timestamp: dictionary[key]?.timestamp,
              status: isbinCompleted ? "COMPLETED" : "NOT STARTED",
              userName: systemValue.userName,
              customer: dictionary[key]?.customer,
              warehouse: dictionary[key]?.warehouse,
              reason: dictionary[key]?.reason || "N/A",
              adjustmentStatus: dictionary[key]?.adjustmentStatus || "N/A",
            };
            dictionary[key] = object;
          }
        }
        if (executions?.length > 0) {
          if (printReport) {
            downloadCSV(dictionary);
          } else {
            setCompareExecution(dictionary);
          }
        } else {
          setCompareExecution({});
        }

        setSelectedDetailedExecution({
          executionId,
          executions,
          systemValues,
          warehouse,
        });
      }
      if (getPlanExecutionsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getPlanExecutionsQuery.error) {
        appState.setAlert(getPlanExecutionsQuery.error.message, "error", 5000);
      }
    }, [
      getPlanExecutionsQuery.loading,
      getPlanExecutionsQuery.data,
      getPlanExecutionsQuery.error,
      getCycleCountPlanQueryAdjustments.data,
    ]);
    useEffect(() => {
      if (searchLocationsQuery.data?.searchLocations?.data) {
        const previousBins = [...selectedSpecificBins];
        const newBinLocations =
          searchLocationsQuery.data.searchLocations?.data?.locations;

        if (previousBins?.length > 0) {
          const previousBins = [...binLocations];

          const uniqueBinsArray = _.uniqBy(
            [...previousBins, ...newBinLocations],
            "code",
          );
          setBinLocations(uniqueBinsArray);
        } else {
          setBinLocations(newBinLocations);
        }
      }

      searchLocationsQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (searchLocationsQuery.data?.searchLocations?.error) {
        appState.setAlert(
          searchLocationsQuery.data?.searchLocations?.error,
          "error",
          5000,
        );
      } else if (searchLocationsQuery.error) {
        appState.setAlert(searchLocationsQuery.error.message, "error", 5000);
      }
    }, [
      searchLocationsQuery.data,
      searchLocationsQuery.loading,
      searchLocationsQuery.error,
    ]);
    // useEffect(() => {
    //   if (binLocationsQuery.data) {
    //     let [rows, sections, bins] = [[], [], []];

    //     binLocationsQuery.data.binLocations.entities?.map((binLocation) => {
    //       const [_, row, section, bin] = binLocation.barcode.split("-");
    //       rows.push(row);
    //       sections.push(section);
    //       bins.push(bin);
    //       // rows.push(row);
    //       // sections.push(row+"-"+section);
    //       // bins.push(row+"-"+section+"-"+bin);

    //     });

    //     rows = _.sortBy(_.uniq(rows)).map((item) => ({ name: item }));
    //     sections = _.sortBy(_.uniq(sections)).map((item) => ({ name: item }));
    //     bins = _.sortBy(_.uniq(bins)).map((item) => ({ name: item }));
    //     setBinLocationOptions({
    //       rows,
    //       bins,
    //       sections,
    //     });
    //     appState.removeLoading();
    //   }
    // }, [
    //   binLocationsQuery.loading,
    //   binLocationsQuery.error,
    //   binLocationsQuery.data,
    // ]);

    useEffect(() => {
      if (executeCycleCountPlanQuery.data) {
        setSelectedCycleCountPlan(null);
        setPlanToActivate(null);
        cycleCountPlansQuery.fetchData();
        appState.setAlert(
          executeCycleCountPlanQuery.data.executeCycleCountPlan.message,
          "success",
          5000,
        );
      }
      if (executeCycleCountPlanQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (executeCycleCountPlanQuery.error) {
        appState.setAlert(
          executeCycleCountPlanQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      executeCycleCountPlanQuery.loading,
      executeCycleCountPlanQuery.data,
      executeCycleCountPlanQuery.error,
    ]);

    useEffect(() => {
      if (saveSkuBinMappingsQuery.data) {
        setSelectedDetailedExecution(null);
        setPlanToActivate(null);
        cycleCountPlansQuery.fetchData();
        appState.setAlert(
          saveSkuBinMappingsQuery.data.saveSkuBinMappings.message,
          "success",
          5000,
        );
      }
      if (saveSkuBinMappingsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveSkuBinMappingsQuery.error) {
        appState.setAlert(saveSkuBinMappingsQuery.error.message, "error", 5000);
      }
    }, [
      saveSkuBinMappingsQuery.loading,
      saveSkuBinMappingsQuery.data,
      saveSkuBinMappingsQuery.error,
    ]);

    useEffect(() => {
      if (getCycleCountPlanQuery.data) {
        setSelectedCycleCountPlan(getCycleCountPlanQuery.data.cycleCountPlan);
        setAvailableInventory(
          getCycleCountPlanQuery.data.cycleCountPlan.skus.map((sku) => ({
            sku,
          })),
        );
      }

      if (getCycleCountPlanQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getCycleCountPlanQuery.loading,
      getCycleCountPlanQuery.data,
      getCycleCountPlanQuery.error,
    ]);

    useEffect(() => {
      if (saveCycleCountPlanQuery.data) {
        setSelectedCycleCountPlan(null);
        cycleCountPlansQuery.fetchData();
      }
      if (saveCycleCountPlanQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveCycleCountPlanQuery.error) {
        appState.setAlert(saveCycleCountPlanQuery.error.message, "error", 5000);
      }
    }, [
      saveCycleCountPlanQuery.loading,
      saveCycleCountPlanQuery.data,
      saveCycleCountPlanQuery.error,
    ]);

    useEffect(() => {
      if (deleteCycleCountPlanQuery.data) {
        appState.hideConfirmation();
        cycleCountPlansQuery.fetchData();
      }
      if (deleteCycleCountPlanQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteCycleCountPlanQuery.error) {
        appState.setAlert(
          deleteCycleCountPlanQuery.error.message,
          "error",
          5000,
        );
        appState.hideConfirmation();
      }
    }, [
      deleteCycleCountPlanQuery.loading,
      deleteCycleCountPlanQuery.data,
      deleteCycleCountPlanQuery.error,
    ]);

    useEffect(() => {
      if (inventoryQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (inventoryQuery.data) {
        let inventory = [...inventoryQuery.data.inventory.entities];
        if (selectedCycleCountPlan?.skus) {
          inventory.push(
            ...selectedCycleCountPlan?.skus.map((item) => ({
              sku: item,
              name: item,
            })),
          );
        }

        setAvailableInventory(inventory);
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this plan?",
        () => {
          deleteCycleCountPlanQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };
    const roleWise = (cycleCountPlans, role) =>
      _.filter(cycleCountPlans, (e) => e.role.name === role);

    const onChange = (e, b) => {
      const cycleCountPlan = {
        ...selectedCycleCountPlan,
      };

      cycleCountPlan[e.target.name] = e.target.value;
      if (e.target.name === "warehouse") {
        cycleCountPlan["specificBins"] = [];
        cycleCountPlan["binRanges"] = {};
        cycleCountPlan["skus"] = [];
        onChangeBinSearch("");
      }
      setSelectedCycleCountPlan(cycleCountPlan);
    };
    const onChangeMultiSelectUsers = (field, value) => {
      const cycleCountPlan = {
        ...selectedCycleCountPlan,
      };
      cycleCountPlan[field] = value;
      setSelectedCycleCountPlan(cycleCountPlan);
    };
    const onChangeMultiSelect = (value, field, innerField) => {
      const cycleCountPlan = {
        ...selectedCycleCountPlan,
      };
      if (field === "specificBins") {
        setSelectedSpecificBins(value);
      }
      if (innerField) {
        cycleCountPlan[field] = cycleCountPlan[field] || {};
        cycleCountPlan[field][innerField] = value;
        setSelectedCycleCountPlan(cycleCountPlan);
        return;
      }

      cycleCountPlan[field] = value;
      setSelectedCycleCountPlan(cycleCountPlan);
    };

    const onChangeSKUs = (e) => {
      setSelectedCycleCountPlan({
        ...selectedCycleCountPlan,
        skus: e.target.value,
      });
    };

    const onChangeInventorySearch = (keyword) => {
      inventoryQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: {
          keyword,
        },
        paginated: false,
      });
    };

    const executePlanClicked = (plan) => {
      setPlanToActivate(plan);
      setItemsAvailableForAssignment({
        skus: [...plan.skus],
        binRanges: {
          rows: plan.binRanges?.rows || [],
          sections: plan.binRanges?.sections || [],
          bins: plan.binRanges?.bins || [],
        },
        specificBins: plan.specificBins || [],
      }); // move to other function and add binranges
    };

    const selectExecutionSku = (record) => {
      let selectedExecutionsCopy = [...selectedSkusForAdjustment];
      const findIndex = selectedExecutionsCopy.findIndex(
        (e) => uniqueAdjustmentRow(e) === uniqueAdjustmentRow(record),
      );
      if (findIndex === -1) {
        selectedExecutionsCopy.push(record);
      } else {
        selectedExecutionsCopy[findIndex] = null;
      }
      setSelectedSkusForAdjusment(_.compact(_.uniq(selectedExecutionsCopy)));
    };

    useEffect(() => {
      if (saveExecutionStockAdjustmentQuery.error) {
        appState.setAlert(
          saveExecutionStockAdjustmentQuery.error.message,
          "error",
          5000,
        );
      }
    }, [saveExecutionStockAdjustmentQuery.error]);

    const adjustStocks = (reason, detailedExecution) => {
      if (selectedSkusForAdjustment) {
        const skusForAdjustment = selectedSkusForAdjustment.filter(
          (item) => item.status !== "UNPROCESSED",
        );

        // Saves plan
        saveExecutionStockAdjustmentQuery.fetchData({
          cycleCountPlanInput: {
            ...selectedPlanExecutions,
            executions: selectedPlanExecutions.executions.map(
              ({ planId: _, ...execution }) => {
                if (execution.id === detailedExecution.executionId) {
                  const adj = {
                    timestamp: Date.now(),
                    reason,
                    adjustments: skusForAdjustment.map((item) => ({
                      sku: item.sku,
                      binLocation: item.binLocation,
                      previousQty: item.availableQuantity,
                      adjustedQty: item.scannedSkus,
                      formFactor: item.uom,
                      nestedFormFactor: item.nestedFormFactor,
                      nestedFormFactorId: item.nestedFormFactorId,
                    })),
                  };
                  return {
                    ...execution,
                    stockAdjustments: execution.stockAdjustments?.length
                      ? [...execution.stockAdjustments, adj]
                      : [adj],
                  };
                }
                return { ...execution };
              },
            ),
          },
        });
        saveSkuBinMappingsQuery.fetchData({
          skuBinMappingsInput: skusForAdjustment.map((item) => {
            return {
              sku: item.sku,
              binLocation: item.binLocation,
              unallocatedQuantity: item.scannedSkus,
              customer: item.customer,
              formFactor: item.uom,
              warehouse: selectedDetailedExecution.warehouse,
              nestedFormFactor: item.uom,
              nestedFormFactorId: item.nestedFormFactorId,
            };
          }),
        });
      }
    };

    /**
     * Generates a filename with a given prefix and the current date.
     *
     * The filename is constructed by appending the current date in the format 'YYYY-MM-DD'
     * to the specified prefix. The '.csv' extension is automatically added to the filename.
     *
     * @param {string} [prefix="export"] - The prefix to be used in the filename.
     * @returns {string} The generated filename in the format 'prefix_YYYY-MM-DD.csv'.
     */
    function generateFilename(prefix = "export") {
      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      return `${prefix}_${dateString}.csv`;
    }

    /**
     * Generates and downloads a CSV file from the provided dictionary data.
     *
     * This function converts a dictionary of data into a CSV formatted string and
     * triggers a download of the file in the browser.
     *
     * @param {Object} dictionary - The dictionary object containing the data to be converted into CSV format.
     *
     */
    function downloadCSV(dictionary) {
      const data = Object.keys(dictionary).map((key) => {
        const item = dictionary[key];
        return {
          Location: item.binLocation || "N/A",
          Product: item.sku || "N/A",
          "System Inventory Count":
            item.systemCount !== undefined ? item.systemCount : "N/A",
          "Physical Inventory Count":
            item.scannedCount !== undefined ? item.scannedCount : "N/A",
          "Count Difference":
            item.systemCount !== undefined && item.scannedCount !== undefined
              ? item.systemCount - item.scannedCount
              : "N/A",
          Agent: item.userName || "N/A",
          "Stock Cycle Status": item.status || "N/A",
          "Inventory Adjustment Status": item.adjustmentStatus || "N/A",
          Reason: item.reason || "N/A",
          "Cycle Count Timestamp":
            moment(parseInt(item.timestamp)).format("llll") || "N/A",
        };
      });

      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }

      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const filename = generateFilename("Cycle_Count");
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);
    return (
      <WrappedComponent
        cycleCountPlans={
          cycleCountPlansQuery.data
            ? cycleCountPlansQuery.data.cycleCountPlans
            : []
        }
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        fetchCycleCountPlan={(id) => getCycleCountPlanQuery.fetchData({ id })}
        selectedCycleCountPlan={selectedCycleCountPlan}
        setSelectedCycleCountPlan={setSelectedCycleCountPlan}
        saveCycleCountPlan={(cycleCountPlan) => {
          saveCycleCountPlanQuery.fetchData({
            cycleCountPlanInput: { ...cycleCountPlan },
          });
        }}
        compareExecution={compareExecution}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        setCompareExecution={setCompareExecution}
        roleWise={roleWise}
        onChangeMultiSelectUsers={onChangeMultiSelectUsers}
        onChange={onChange}
        onChangeMultiSelect={onChangeMultiSelect}
        deleteButtonClicked={deleteButtonClicked}
        binLocationOptions={binLocationOptions}
        specificBinLocationOptions={binLocations}
        skus={availableInventory}
        availableInventory={availableInventory}
        onChangeInventorySearch={debouncedInventorySearch}
        onChangeSKUs={onChangeSKUs}
        executePlanClicked={executePlanClicked}
        setPlanToActivate={setPlanToActivate}
        planToActivate={planToActivate}
        onAddUserToPlan={(user) => {
          if (!user) return;
          const personnel = {
            id: user.id,
            name: user.name,
            assignedItems: [],
          };
          setPlanToActivate({
            ...planToActivate,
            personnel: planToActivate?.personnel?.length
              ? [...planToActivate.personnel, personnel]
              : [personnel],
          });
        }}
        onRemoveUserFromPlan={(userId) => {
          const personnelMapping = planToActivate.personnel.filter(
            (item) => item.id !== userId,
          );
          setPlanToActivate({ ...planToActivate, personnel: personnelMapping });
        }}
        executePlan={() => {
          executeCycleCountPlanQuery.fetchData({
            executePlanInput: {
              planId: planToActivate.id,
              personnel: planToActivate.personnel,
              binRanges: planToActivate.binRanges,
              skus: planToActivate.skus,
              specificBins: planToActivate.specificBins,
            },
          });
        }}
        onChangePersonnelMapping={(idx, e) => {
          const personnelMapping = planToActivate.personnel;
          personnelMapping[idx] = {
            ...personnelMapping[idx],
            assignedItems: e,
          };

          setPlanToActivate({ ...planToActivate, personnel: personnelMapping });
        }}
        itemsAvailableForAssignment={itemsAvailableForAssignment}
        selectedPlanExecutions={selectedPlanExecutions}
        setSelectedPlanExecutions={setSelectedPlanExecutions}
        selectedDetailedExecution={selectedDetailedExecution}
        setSelectedDetailedExecution={setSelectedDetailedExecution}
        onClickExecutions={(plan) => {
          setSelectedPlanExecutions({
            ...plan,
            executions: plan.executions.map((item) => ({
              ...item,
              planId: plan.id,
            })),
          });
        }}
        onClickDetailedExecutions={(executionId, planId, printReport) => {
          setPrintReport(printReport);

          getPlanExecutionsQuery.fetchData({ executionId, planId });
          getCycleCountPlanQueryAdjustments.fetchData({ id: planId });
        }}
        onClickAdjustStocks={setSelectedSkusForAdjusment}
        selectedSkusForAdjustment={selectedSkusForAdjustment}
        selectExecutionSku={selectExecutionSku}
        setSelectedSkusForAdjusment={setSelectedSkusForAdjusment}
        submitStockAdjustment={(data) => {
          adjustStocks(data, selectedDetailedExecution);
        }}
        debouncedBinLocationSearch={debouncedBinLocationSearch}
        printReport={printReport}
      />
    );
  };
};

export const uniqueAdjustmentRow = (row) => {
  return (
    row.binLocation +
    "-" +
    row.sku +
    "-" +
    row.uom +
    "-" +
    row.barcodeScanned +
    "-" +
    row.nestedFormFactorId
  );
};

export default withCycleCountPlansLogic;
