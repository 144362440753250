import React, { useState, useEffect } from "react";
import SlideOverPanel from "#components/common/SlideOverPanel";
import { useQuery } from "#hooks/useQuery";
import { GET_PRODUCTS } from "#queries";
import _ from "lodash";
import ModalV2 from "#components/utils/ModalV2";
import { TrashIcon } from "@heroicons/react/outline";

const PRODUCTS_PER_PAGE = 25;

const LocationReservationMenu = ({
  storageLocation,
  showReservationsMenu,
  setShowReservationsMenu,
  setStorageLocation,
  customers,
  reserveLocation,
  locationReservations,
}) => {
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const [activeTab, setActiveTab] = useState(() => {
    const hasClientReservation = locationReservations.some(
      (reservation) => reservation.strategy === "CLIENT",
    );
    const hasProductReservation = locationReservations.some(
      (reservation) => reservation.strategy === "PRODUCT",
    );
    if (hasClientReservation) {
      return "Clients";
    } else if (hasProductReservation) {
      return "Products";
    } else {
      return "Clients";
    }
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [selectionModal, setSelectionModal] = useState(false);
  const [localReservations, setLocalReservations] = useState(
    locationReservations || [],
  );
  const [selectedClientForProducts, setSelectedClientForProducts] =
    useState(null);

  const [selectedReservations, setSelectedReservations] = useState(
    locationReservations || [],
  );
  const [productsPageNo, setProductsPageNo] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [currentReservationStrategy, setCurrentReservationStrategy] =
    useState(null);

  useEffect(() => {
    const hasClientReservation = selectedReservations.some(
      (reservation) => reservation.strategy === "CLIENT",
    );
    const hasProductReservation = selectedReservations.some(
      (reservation) => reservation.strategy === "PRODUCT",
    );

    if (hasClientReservation) {
      setCurrentReservationStrategy("Clients");
    } else if (hasProductReservation) {
      setCurrentReservationStrategy("Products");
    } else {
      setCurrentReservationStrategy(null);
    }
  }, [selectedReservations]);

  useEffect(() => {
    if (selectedClientForProducts) {
      setProductsPageNo(1);
      onChangeProductSearch("", selectedClientForProducts);
    }
  }, [selectedClientForProducts]);

  useEffect(() => {
    setLocalReservations(locationReservations);
  }, [locationReservations]);

  useEffect(() => {
    if (activeTab === "Products") {
      debouncedProductSearch(searchQuery);
    }
  }, [searchQuery, activeTab]);

  useEffect(() => {
    setSearchQuery("");
  }, [activeTab]);

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    return text.split(regex).map((part, i) =>
      regex.test(part) ? (
        <span key={i} className="bg-yellow-200">
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  const confirmChanges = () => {
    setSelectedReservations(localReservations);
    setSelectionModal(false);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) return text;
    return text?.slice(0, maxLength) + "...";
  };

  const onChangeProductSearch = async (searchKeyword, clientId = null) => {
    try {
      const response = await getProductsQuery.fetchData({
        perPage: PRODUCTS_PER_PAGE,
        pageNumber: 1,
        filters: {
          keyword: searchKeyword,
          customer: clientId ? [clientId] : undefined,
        },
        sort: "-createdAt",
      });

      if (response.data?.products?.entities) {
        setSearchedProducts(response.data.products.entities);
        setProductsPageNo(2);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const checkProductPagination = async (clientId) => {
    const totalProducts = getProductsQuery.data?.products?.total;
    const currentProductCount = searchedProducts.length;

    if (totalProducts && currentProductCount < totalProducts) {
      const response = await getProductsQuery.fetchData({
        perPage: PRODUCTS_PER_PAGE,
        pageNumber: productsPageNo,
        filters: {
          customer: clientId ? [clientId] : undefined,
        },
        sort: "-createdAt",
      });

      return response;
    }
    return null;
  };

  const handleProductScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = 10;

    if (
      scrollTop + clientHeight + threshold >= scrollHeight &&
      !isFetchingMore
    ) {
      setIsFetchingMore(true);

      const paginatedProductsResponse = await checkProductPagination(
        selectedClientForProducts,
      );

      if (paginatedProductsResponse?.data?.products?.entities) {
        setSearchedProducts((prev) => [
          ...prev,
          ...paginatedProductsResponse.data.products.entities,
        ]);
        setProductsPageNo((prev) => prev + 1);
      }
      setIsFetchingMore(false);
    }
  };

  const handleSelectionInModal = (
    itemId,
    itemName,
    itemCode,
    isCustomer = true,
  ) => {
    setLocalReservations((prev) => {
      const locationId = storageLocation.backendId;
      const locationCode = storageLocation.code;

      const existingReservation = prev.find(
        (reservation) =>
          reservation.strategy === (isCustomer ? "CLIENT" : "PRODUCT"),
      );

      if (!existingReservation) {
        const newReservation = {
          locationId,
          locationCode,
          strategy: isCustomer ? "CLIENT" : "PRODUCT",
          customers: isCustomer
            ? [{ id: itemId, name: itemName, code: itemCode, toDelete: false }]
            : [],
          products: !isCustomer
            ? [{ id: itemId, name: itemName, sku: itemCode, toDelete: false }]
            : [],
        };

        setCurrentReservationStrategy(isCustomer ? "Clients" : "Products");
        return [...prev, newReservation];
      }

      const updatedReservations = prev.map((reservation) => {
        if (isCustomer && reservation.strategy === "CLIENT") {
          const existingCustomer = reservation.customers.find(
            (customer) => customer.id === itemId,
          );

          if (existingCustomer) {
            return {
              ...reservation,
              customers: reservation.customers.map((customer) =>
                customer.id === itemId
                  ? { ...customer, toDelete: !customer.toDelete }
                  : customer,
              ),
            };
          } else {
            return {
              ...reservation,
              customers: [
                ...reservation.customers,
                { id: itemId, name: itemName, code: itemCode, toDelete: false },
              ],
            };
          }
        } else if (!isCustomer && reservation.strategy === "PRODUCT") {
          const existingProduct = reservation.products.find(
            (product) => product.id === itemId,
          );

          if (existingProduct) {
            return {
              ...reservation,
              products: reservation.products.map((product) =>
                product.id === itemId
                  ? { ...product, toDelete: !product.toDelete }
                  : product,
              ),
            };
          } else {
            return {
              ...reservation,
              products: [
                ...reservation.products,
                { id: itemId, name: itemName, sku: itemCode, toDelete: false },
              ],
            };
          }
        }
        return reservation;
      });

      return updatedReservations;
    });
  };

  const debouncedProductSearch = _.debounce(onChangeProductSearch, 500);

  const activeItems = activeTab === "Clients" ? customers : searchedProducts;

  const filteredItems = (activeItems || []).filter((item) => {
    const query = searchQuery.toLowerCase();

    if (activeTab === "Clients") {
      const matchesSearchQuery =
        item?.name?.toLowerCase()?.includes(query) ||
        item?.code?.toLowerCase()?.includes(query);

      return matchesSearchQuery;
    } else if (activeTab === "Products") {
      const matchesSearchQuery =
        item?.name?.toLowerCase()?.includes(query) ||
        item?.sku?.toLowerCase()?.includes(query);

      return matchesSearchQuery;
    }

    return false;
  });

  return (
    <SlideOverPanel
      open={showReservationsMenu}
      setOpen={setShowReservationsMenu}
      title={"Manage Reservations"}>
      <div className="relative mb-6 flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-4">
        <div className="mb-4">
          <h2 className="text-xl font-bold">{storageLocation.code}</h2>
          <div className="mt-2 rounded-md border bg-yellow-100 p-3 text-sm text-yellow-800">
            <ul className="list-inside list-disc">
              <li>
                All the sub hierarchies coming under this location will be made
                unavailable.
              </li>
              <li>
                Changing any sub hierarchies location will have an impact on the
                main hierarchy if switched from unavailable to available.
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-6 rounded-md border border-[#224E73] bg-[#F1F8FF] p-4">
          <h4 className="text-sm font-medium text-gray-700">
            Selection Overview
          </h4>
          <div className="mt-2 flex space-x-8">
            <div>
              <span className="text-gray-600">Clients: </span>
              <span className="font-bold text-gray-800">
                {selectedReservations.reduce(
                  (count, reservation) => count + reservation.customers.length,
                  0,
                )}
              </span>
            </div>
            <div>
              <span className="text-gray-600">Products: </span>
              <span className="font-bold text-gray-800">
                {selectedReservations.reduce(
                  (count, reservation) => count + reservation.products.length,
                  0,
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <h4 className="text-lg font-semibold text-gray-700">
            Select Reservation Strategy
          </h4>
          <div className="mt-2 flex gap-4">
            <label
              className={`flex items-center rounded-lg border p-3 ${
                currentReservationStrategy === "Products"
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}>
              <input
                type="radio"
                name="strategy"
                value="Clients"
                className="mr-2 font-semibold"
                checked={activeTab === "Clients"}
                onChange={() => setActiveTab("Clients")}
                disabled={currentReservationStrategy === "Products"}
              />
              Clients
            </label>

            <label
              className={`flex items-center rounded-lg border p-3 ${
                currentReservationStrategy === "Clients"
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}>
              <input
                type="radio"
                name="strategy"
                value="Products"
                className="mr-2 font-semibold"
                checked={activeTab === "Products"}
                onChange={() => setActiveTab("Products")}
                disabled={currentReservationStrategy === "Clients"}
              />
              Products
            </label>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex items-center justify-between">
            <h4 className="font-semibold">{activeTab}</h4>
            <div
              className="cursor-pointer font-semibold text-[#224E73] underline"
              onClick={() => setSelectionModal(true)}>
              + Add {activeTab}
            </div>
          </div>
        </div>

        {activeTab === "Clients" && (
          <div>
            <div className="mb-6 mt-2 rounded-md border border-[#224E73] bg-[#F1F8FF] p-3 text-sm">
              <ul className="list-inside list-disc">
                <li>
                  Please note that all the products associated with the selected
                  clients will also be reserved for the selected location
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {selectedReservations?.map((reservation) => (
            <React.Fragment key={reservation.id}>
              {reservation.strategy === "CLIENT" &&
                activeTab === "Clients" &&
                reservation.customers?.map((client) => (
                  <div
                    key={client.id}
                    className={`relative flex w-full flex-col rounded-md border p-3 shadow-sm ${
                      client.toDelete ? "border-red-500" : "border-gray-200"
                    }`}>
                    <div className="flex items-start justify-between">
                      <div>
                        <h5
                          className="max-w-[150px] truncate text-base font-medium hover:cursor-pointer"
                          title={client.name}>
                          {truncateText(client.name, 8)}
                        </h5>
                        <p className="text-xs text-gray-500">{client.code}</p>
                        <p
                          onClick={() => {
                            setSelectedClientForProducts(client.id);
                            onChangeProductSearch("", client.id);
                          }}
                          className="cursor-pointer text-[#224E73] underline">
                          View Products
                        </p>
                      </div>
                      {/* <button className="text-red-500 hover:text-red-600">
                        <TrashIcon className="h-6 w-6" />
                      </button> */}
                    </div>
                  </div>
                ))}

              {activeTab === "Products" &&
                reservation.strategy === "PRODUCT" &&
                reservation.products?.map((product) => (
                  <div
                    key={product.id}
                    className={`relative flex w-full flex-col rounded-md border p-3 shadow-sm ${
                      product.toDelete ? "border-red-500" : "border-gray-200"
                    }`}>
                    <div className="flex items-start justify-between">
                      <div>
                        <h5
                          className="max-w-[150px] truncate text-base font-medium hover:cursor-pointer"
                          title={product.name}>
                          {truncateText(product.name, 8)}
                        </h5>
                        <p className="text-xs text-gray-500">
                          SKU: {product.sku}
                        </p>
                      </div>
                      {/* <button className="text-red-500 hover:text-red-600">
                        <TrashIcon className="h-6 w-6" />
                      </button> */}
                    </div>
                  </div>
                ))}
            </React.Fragment>
          ))}
        </div>

        <div className="mt-6 flex justify-end">
          <button
            onClick={() => {
              setShowReservationsMenu(false);
              setStorageLocation(null);
            }}
            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50">
            Cancel
          </button>
          <button
            onClick={() => {
              reserveLocation(storageLocation.backendId, selectedReservations);
              setShowReservationsMenu(false);
              setStorageLocation(null);
              setSelectedReservations([]);
            }}
            className="ml-3 rounded-md bg-[#224E73] px-4 py-2 text-white shadow-sm hover:bg-[#1c3e5e]">
            Proceed
          </button>
        </div>
      </div>

      {selectionModal && (
        <ModalV2
          onClose={() => {
            setSelectionModal(false);
            setLocalReservations([]);
          }}
          xIconClicked={() => {
            setSelectionModal(false);
            setLocalReservations([]);
          }}
          title={`Add ${activeTab}`}
          height={"480px"}
          negativeAction={() => {
            setSelectionModal(false);
            setLocalReservations([]);
          }}
          positiveAction={confirmChanges}
          positiveClassName={"bg-[#224E73] text-white"}
          negativeClassname={"text-gray-700 border border-[#224E73]"}
          positiveText="Confirm"
          scrollWithin={true}>
          <div className="flex flex-col space-y-4">
            <div className="mb-4">
              <input
                type="text"
                placeholder={`Search ${activeTab}...`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-1 focus:ring-[#224E73]"
              />
            </div>

            <div className="space-y-4">
              {filteredItems?.map((item, index) => {
                const isChecked = localReservations.some((reservation) => {
                  if (activeTab === "Clients") {
                    return (
                      reservation.strategy === "CLIENT" &&
                      reservation.customers.some(
                        (customer) =>
                          customer.id === item.id && customer.toDelete !== true,
                      )
                    );
                  } else if (activeTab === "Products") {
                    return (
                      reservation.strategy === "PRODUCT" &&
                      reservation.products.some(
                        (product) =>
                          product.id === item.id && product.toDelete !== true,
                      )
                    );
                  }
                  return false;
                });

                return (
                  <div
                    key={index}
                    className="flex items-center rounded-md border border-gray-200 p-2 shadow-sm">
                    <input
                      type="checkbox"
                      id={`${activeTab}-${index}`}
                      className="mr-4"
                      checked={isChecked}
                      onChange={() =>
                        handleSelectionInModal(
                          item.id,
                          item.name,
                          item.code || item.sku,
                          activeTab === "Clients",
                        )
                      }
                    />
                    <label
                      htmlFor={`${activeTab}-${index}`}
                      className="flex-grow">
                      <div className="text-sm font-medium text-gray-700">
                        {highlightText(
                          truncateText(item.name, 50),
                          searchQuery,
                        )}
                      </div>
                      <div className="text-xs text-gray-500">
                        {activeTab === "Clients" &&
                          highlightText(item.code, searchQuery)}
                        {activeTab === "Products" && (
                          <div>SKU: {highlightText(item.sku, searchQuery)}</div>
                        )}
                      </div>
                    </label>
                    <div className="text-sm text-gray-500">
                      {isChecked ? "Reserved" : ""}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalV2>
      )}
      {selectedClientForProducts && (
        <ModalV2
          onClose={() => {
            setSelectionModal(false);
            setSelectedClientForProducts(null);
          }}
          xIconClicked={() => {
            setSelectionModal(false);
            setSelectedClientForProducts(null);
          }}
          title={`Client ${customers.find((c) => c.id === selectedClientForProducts)?.name}: Product List`}
          height={"480px"}
          negativeAction={() => {
            setSelectionModal(false);
            setSelectedClientForProducts(null);
          }}
          positiveAction={() => {
            setSelectionModal(false);
            setSelectedClientForProducts(null);
          }}
          positiveClassName={"bg-[#224E73] text-white"}
          negativeClassname={"text-gray-700 border border-[#224E73]"}
          positiveText="Close"
          scrollWithin={true}>
          <div
            className="max-h-96 space-y-4 overflow-y-auto"
            onScroll={handleProductScroll}>
            {searchedProducts?.map((product, index) => {
              const isChecked = true;
              const isDisabled = true;

              return (
                <div
                  key={index}
                  className="flex items-center rounded-md border border-gray-200 bg-gray-100 p-2 shadow-sm">
                  <input
                    type="checkbox"
                    id={`Product-${index}`}
                    className="mr-4 cursor-not-allowed"
                    checked={isChecked}
                    disabled={isDisabled}
                  />
                  <label htmlFor={`Product-${index}`} className="flex-grow">
                    <div className="text-sm font-medium text-gray-400">
                      {truncateText(product.name, 50)}
                    </div>
                    <div className="text-xs text-gray-400">
                      SKU: {product.sku}
                    </div>
                  </label>
                </div>
              );
            })}

            {isFetchingMore && <div>Loading more products...</div>}
          </div>
        </ModalV2>
      )}
    </SlideOverPanel>
  );
};

export default LocationReservationMenu;
