import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_BUNDLING_TASKS,
  SCAN_BUNDLE,
  SCAN_BUNDLING_ITEM,
  COMPLETE_BUNDLING_ITEM,
  COMPLETE_BUNDLE,
  DELETE_BUNDLING_CHILD_TASK,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { printCanvasLabelPdf } from "#utils/printCanvasLabelPdf";

const withBundlerLogic = (WrappedComponent) => {
  return (props) => {
    // state declarations
    const [bundlingTasks, setBundlingTasks] = useState([]);
    const [childTask, setChildTask] = useState(null);
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [selectedBundleItem, setSelectedBundleItem] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [selectedTote, setSelectedTote] = useState(null);
    const [customer, setCustomer] = useState(undefined); // TO DO: set customer from batch details.

    // context declarations
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);

    // query declarations
    const scanBundleQuery = useQuery(SCAN_BUNDLE);
    const scanBundleItemQuery = useQuery(SCAN_BUNDLING_ITEM);
    const completeBundlingItemQuery = useQuery(COMPLETE_BUNDLING_ITEM);
    const completeBundleQuery = useQuery(COMPLETE_BUNDLE);
    const getBundlingTasksQuery = useQuery(GET_BUNDLING_TASKS);
    const deleteChildTaskQuery = useQuery(DELETE_BUNDLING_CHILD_TASK);

    // init effect
    useEffect(() => {
      if (auth && auth.user && auth.user.warehousesList) {
        const warehouses = auth.user.warehousesList;
        if (warehouses.length === 1) {
          setSelectedWarehouse(warehouses[0].id);
        }
      }
      getBundlingTasks();
    }, []);

    useEffect(() => {
      getBundlingTasks();
    }, [selectedWarehouse, selectedTote]);

    // fetch bundling task query
    useEffect(() => {
      getBundlingTasksQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();
      if (getBundlingTasksQuery.data) {
        const tasks = getBundlingTasksQuery.data.getBundlingTasks;
        if (
          selectedTote &&
          selectedWarehouse &&
          tasks.length > 0 &&
          tasks[0].type === "BUNDLING"
          //  &&
          // tasks[0].tote === selectedTote
        ) {
          console.log("coming inside if statement");
          if (tasks[0].tote === selectedTote) {
            const task = tasks[0].id;
            console.log("task", task);
            let barcode = null;
            for (let i = 0; i < tasks[0].bundledInventory.length; i++) {
              const item = tasks[0].bundledInventory[i];
              if (item.status == "UNPROCESSED") {
                console.log("status is unprocessed ");
                barcode = item.uniqueIdentifier;
                scanBundle(task, barcode);
                setSelectedTote(null);
                break;
              }
            }
          }
        } else {
          if (tasks.length === 1 && tasks[0].type === "BUNDLING_CHILD") {
            setBundlingTasks([]);
            setChildTask(tasks[0]);
            setSelectedBundle(tasks[0].currentBundledItem);
            setSelectedBundleItem(tasks[0].currentItem);
          } else {
            setChildTask(null);
            setBundlingTasks(tasks);
          }
        }
      }
      if (getBundlingTasksQuery.error) {
        appState.setAlert(getBundlingTasksQuery.error.message, "error", 5000);
        appState.removeLoading();
      }
    }, [
      getBundlingTasksQuery.loading,
      getBundlingTasksQuery.data,
      getBundlingTasksQuery.error,
    ]);

    // scan bundle
    useEffect(() => {
      scanBundleQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (scanBundleQuery.data) {
        const task = scanBundleQuery.data.scanBundle;
        setChildTask(task);
        setSelectedBundle(task.currentBundledItem);
        setSelectedBundleItem(task.currentItem);
      }

      if (scanBundleQuery.error) {
        appState.setAlert(scanBundleQuery.error.message, "error", 5000);
      }
    }, [scanBundleQuery.loading, scanBundleQuery.data, scanBundleQuery.error]);

    // scan item in bundle
    useEffect(() => {
      scanBundleItemQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (scanBundleItemQuery.data) {
        appState.setAlert(
          scanBundleItemQuery.data.scanItemInBundle.message,
          "success",
          4000,
        );
        getBundlingTasks();
      }

      if (scanBundleItemQuery.error) {
        appState.setAlert(scanBundleItemQuery.error.message, "error", 5000);
      }
    }, [
      scanBundleItemQuery.loading,
      scanBundleItemQuery.data,
      scanBundleItemQuery.error,
    ]);

    // complete item in bundle by entering quantity
    useEffect(() => {
      completeBundlingItemQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (completeBundlingItemQuery.data?.completeItemInBundle?.message) {
        appState.setAlert(
          completeBundlingItemQuery.data.completeItemInBundle.message,
          "success",
          5000,
        );
        getBundlingTasks();
      }

      if (completeBundlingItemQuery.error) {
        appState.setAlert(
          completeBundlingItemQuery.error.message,
          "error",
          5000,
        );
        appState.removeLoading();
      }
    }, [
      completeBundlingItemQuery.loading,
      completeBundlingItemQuery.data,
      completeBundlingItemQuery.error,
    ]);

    // complete bundle - at this stage make sure the Labels are printed before calling this query.
    useEffect(() => {
      completeBundleQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (completeBundleQuery.data?.completeBundle?.message) {
        appState.setAlert(
          completeBundleQuery.data.completeBundle.message,
          "success",
          5000,
        );
        getBundlingTasks();
      }

      if (completeBundleQuery.error) {
        appState.setAlert(completeBundleQuery.error.message, "error", 5000);
        appState.removeLoading();
      }
    }, [
      completeBundleQuery.loading,
      completeBundleQuery.data,
      completeBundleQuery.error,
    ]);

    // Delete the child task allocated for current user - acts as skip the bundle
    useEffect(() => {
      deleteChildTaskQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (deleteChildTaskQuery.data) {
        appState.setAlert(
          deleteChildTaskQuery.data.deleteBundlingChildTask.message,
          "success",
          5000,
        );
        setChildTask(null);
        setSelectedBundle(null);
        setSelectedBundleItem(null);
        getBundlingTasks();
      }

      if (deleteChildTaskQuery.error) {
        appState.setAlert(deleteChildTaskQuery.error.message, "error", 5000);
        appState.removeLoading();
      }
    }, [
      deleteChildTaskQuery.loading,
      deleteChildTaskQuery.data,
      deleteChildTaskQuery.error,
    ]);

    const getBundlingTasks = () => {
      if (!selectedWarehouse) return;
      const queryFilter = {
        warehouse: selectedWarehouse,
      };
      if (selectedTote) queryFilter.tote = selectedTote;
      getBundlingTasksQuery.fetchData({
        warehouse: selectedWarehouse,
        tote: selectedTote,
      });
    };

    const onChangeScannedQuantity = (qty, type = "BUNDLE_ITEM") => {
      if (type === "BUNDLE_ITEM") {
        setSelectedBundleItem({
          ...selectedBundleItem,
          scannedQuantity:
            isNaN(parseInt(qty)) === false ? parseInt(qty) : null,
        });
      } else if (type === "BUNDLE") {
        setSelectedBundle({
          ...selectedBundle,
          scannedQuantity:
            isNaN(parseInt(qty)) === false ? parseInt(qty) : null,
        });
      }
    };

    // to scan the selected bundle item or tote
    const scanBundleItem = (code) => {
      scanBundleItemQuery.fetchData({
        task: childTask.parents[0].id,
        childTask: childTask.id,
        barcode: code,
      });
    };

    // to scan a bundle for selection
    const scanBundle = (taskId, uniqueIdentifier) => {
      scanBundleQuery.fetchData({
        task: taskId,
        barcode: uniqueIdentifier,
        // TO DO: optionally we need to include station
      });
    };

    // to confirm the selected bundle item
    const confirmBundleItem = () => {
      if (
        selectedBundleItem.scannedQuantity <= 0 &&
        selectedBundleItem.scannedQuantity > selectedBundleItem.quantity
      ) {
        return appState.setAlert(
          "Please enter correct quantity",
          "error",
          5000,
        );
      }
      completeBundlingItemQuery.fetchData({
        task: childTask.parents[0].id,
        childTask: childTask.id,
        quantity: selectedBundleItem.scannedQuantity,
      });
    };

    // to confirm the selected bundle
    const confirmBundle = () => {
      completeBundleQuery.fetchData({
        task: childTask.parents[0].id,
        childTask: childTask.id,
        barcode: selectedBundle.uniqueIdentifier,
        quantity: selectedBundle.scannedQuantity,
      });
    };

    // to delete current working child task
    const deleteChildTask = () => {
      deleteChildTaskQuery.fetchData({
        task: childTask.parents[0].id,
        childTask: childTask.id,
      });
    };

    // print labels for the selected bundle before completing child task. this is to paste the printed labels on the bundles made.
    const printBundlingLabels = () => {
      appState.setLoading();
      appState.setAlert(`Printing SKU`, "success", 3000);
      const code = selectedBundle.sku;
      const isDirectPrinting = ["uat"].includes(appState.subdomain)
        ? false
        : true;

      printCanvasLabelPdf(
        `${code}`,
        selectedBundle.scannedQuantity,
        null,
        appState.subdomain === "tosprep" ? "2x1" : null,
        appState.subdomain === "tosprep" || appState.subdomain === "weprepfba"
          ? "2x1"
          : "3x1",
        appState,
        isDirectPrinting,
        selectedBundle.productName,
      );

      setTimeout(() => {
        appState.removeLoading();
      }, 2000);
    };

    return (
      <WrappedComponent
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        selectedTote={selectedTote}
        setSelectedTote={setSelectedTote}
        bundlingTasks={bundlingTasks}
        childTask={childTask}
        deleteChildTask={deleteChildTask}
        selectedBundle={selectedBundle}
        selectedBundleItem={selectedBundleItem}
        scanBundle={scanBundle}
        scanBundleItem={scanBundleItem}
        confirmBundleItem={confirmBundleItem}
        printBundlingLabels={printBundlingLabels}
        confirmBundle={confirmBundle}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        customer={customer}
        onChangeScannedQuantity={onChangeScannedQuantity}
      />
    );
  };
};

export default withBundlerLogic;
