export default {
  IS_BRANDED: "is-branded", // if a particular tenant is a brand or not.
  IS_BUNDLING_WORKFLOW: "bundling-workflow",
  TO_USE_NEW_STYLE_BUNDLE: "to-use-new-style-bundle",
  BATCH_SETTINGS_ENABLED: "batch-settings-enabled",
  LOT_ID_SELECTION_ENABLED: "lot-id-selection-enabled",
  FBA_CASE_PACK_ENABLED: "fba-case-pack-enabled",
  BOL_UPLOAD_ENABLED: "bol-upload-enabled",
  FBA_SKU_LABEL_GENERATION: "fba-sku-label-generation",
  SHIPPING_DESKTOP_WORKFLOW_ENABLED: "shipping-workflow-desktop-enabled",
  CSV_PACKING_LIST_DOWNLOAD_ENABLED: "csv-packing-list-download-enabled",
  TRANSACTION_HISTORY_REPORT_ENABLED: "transaction-history-report-enabled",
  BILLING_MODULE_ENABLED: "billing-module-enabled",
  LPN_SELECTION_ENABLED: "lpn-selection-enabled",
  COG_ENABLED: "cog-enabled",
  IS_MARKETPLACE_IMPORT: "marketplace-import",
  ASSOCIATE_USERNAME_INPUT_ENABLED: "associate-username-input-enabled",
  FBA_2D_BARCODE_ENABLED: "fba-2d-barcode-enabled",
  DEFINE_BUNDLE_ON_CONSIGNMENT: "define-bundles-on-consignment",
  HIERARCHICAL_STORAGE_MANAGEMENT_ENABLED:
    "hierarchical-storage-management-enabled",
  PRODUCT_LINKAGE_ALLOWED: "product-linkage-allowed",
  SALES_PORTAL_ENABLED: "sales-portal-enabled",
  BULK_UPLOAD_V2_ENABLED: "WAREHOUSE_BULK_UPLOAD",
};
