import React from "react";

export const BANNER_TYPE_ENUM = {
  INITIATED: "INITIATED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  PARTIAL_COMPLETE: "PARTIAL_COMPLETE",
};

const ExportBanner = ({ onClose, typeOfBanner, content }) => {
  const classYellow =
    "left-0 right-0 bg-yellow-100 text-black-800 p-4 flex items-center justify-between z-50 mb-1";
  const classGreen =
    "left-0 right-0 bg-green-100 text-black-800 p-4 flex items-center justify-between z-50 mb-1";
  const classRed =
    "left-0 right-0 bg-red-100 text-black-800 p-4 flex items-center justify-between z-50 mb-1";
  const classOrange =
    "left-0 right-0 orange-100 text-black-800 p-4 flex items-center justify-between z-50 mb-1";

  let bannerClass = "";
  if (typeOfBanner === BANNER_TYPE_ENUM?.INITIATED) {
    bannerClass = classYellow;
  } else if (typeOfBanner === BANNER_TYPE_ENUM?.COMPLETED) {
    bannerClass = classGreen;
  } else if (typeOfBanner === BANNER_TYPE_ENUM?.FAILED) {
    bannerClass = classRed;
  } else if (typeOfBanner === BANNER_TYPE_ENUM?.PARTIAL_COMPLETE) {
    bannerClass = classOrange;
  }

  return (
    <div className={bannerClass}>
      <div className="flex items-center">
        {typeOfBanner === BANNER_TYPE_ENUM?.INITIATED && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            strokeWidth="1.5"
            stroke="currentColor"
            className="text-yellow-500">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
            />
          </svg>
        )}
        {typeOfBanner === BANNER_TYPE_ENUM?.COMPLETED && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            strokeWidth="1.5"
            stroke="currentColor"
            className="text-green-500">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        )}
        {typeOfBanner === BANNER_TYPE_ENUM?.FAILED && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            strokeWidth="1.5"
            stroke="currentColor"
            className="text-red-500">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>
        )}
        {typeOfBanner === BANNER_TYPE_ENUM?.PARTIAL_COMPLETE && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            strokeWidth="1.5"
            stroke="currentColor"
            className="txt-orange-500">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>
        )}

        {content}
      </div>
      <button className="text-xl font-bold" onClick={() => onClose()}>
        &times;
      </button>
    </div>
  );
};

export default ExportBanner;
