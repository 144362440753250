import React, { useContext, useEffect, useRef, useState } from "react";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { ModalContext } from "#newUiComponents/commons/bulkUploadV2/useReducer";
import { AppStateContext } from "#contexts/appState";
import Papa from "papaparse";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/solid";
import TextArea from "#newUiComponents/commons/TextArea";
import { Tooltip } from "antd";
import Error from "#components/common/Error";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";

export const FILE_SIZE_25_MB_VALIDATION = 25600; // 25 MB(25600 KB)
export const FILE_SIZE_1_MB_VALIDATION = 1024; // 1 MB (1024 KB)
export const FILE_ROWS_VALIDATION_10000 = 10001;
export const FILE_UPLOAD_STEP_ID = "FILE_UPLOAD";
export const MAP_FIELDS_STEP_ID = "MAP_FIELDS";

const UploadBulk = ({
  dashboardFields,
  BULK_UPLOAD_DUPLICATE_VALIDATION,
  onClose,
}) => {
  const bulkUploadEntity = useContext(ModalContext);

  const [showSubmit, setShowSubmit] = useState(false);

  useEffect(() => {
    let formedSteps = bulkUploadEntity?.steps;
    if (formedSteps && formedSteps?.length !== 0) {
      formedSteps[0] = {
        ...formedSteps[0],
        status: "current",
      };

      bulkUploadEntity?.setSteps(formedSteps);
      bulkUploadEntity?.setSelectedStep(formedSteps[0]);
    }
  }, []);

  const verifyNextSteps = () => {
    // Step 01 Validation
    if (
      bulkUploadEntity?.steps &&
      bulkUploadEntity?.selectedStep &&
      bulkUploadEntity?.steps.findIndex(
        (step) => step["status"] === "current",
      ) === 0 &&
      bulkUploadEntity?.selectedStep?.isVerify
    ) {
      if (
        !bulkUploadEntity?.selectedFile ||
        !bulkUploadEntity?.s3PresignedURL ||
        !bulkUploadEntity?.s3FileName
      )
        return;
      bulkUploadEntity?.setToStartFileUploadOnS3({
        startFileUpload: true,
        fileUploadedOnS3: false,
      });
    } else {
      setNextStep();
    }
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(bulkUploadEntity?.steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      bulkUploadEntity?.setSteps(copiedSteps);
      bulkUploadEntity?.setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(bulkUploadEntity?.steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));
      bulkUploadEntity?.setErrors(null);
      bulkUploadEntity?.setSteps(copiedSteps);
      setShowSubmit(false);
      bulkUploadEntity?.setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const onCancelForm = () => {
    onClose();
  };

  useEffect(() => {
    if (bulkUploadEntity?.fileUploadedOnS3) {
      setNextStep();
    }
  }, [bulkUploadEntity?.fileUploadedOnS3]);

  return (
    <div className="relative flex h-full w-full flex-col overflow-auto font-inter">
      <header className="space-y-2 py-4">
        <Navbar steps={bulkUploadEntity?.steps} />
      </header>

      <main className="my-2 h-full flex-grow overflow-auto">
        {bulkUploadEntity?.selectedStep?.id === FILE_UPLOAD_STEP_ID && (
          <FileUpload
            dashboardFields={dashboardFields}
            BULK_UPLOAD_DUPLICATE_VALIDATION={BULK_UPLOAD_DUPLICATE_VALIDATION}
          />
        )}
        {bulkUploadEntity?.selectedStep?.id === MAP_FIELDS_STEP_ID && (
          <MapFields />
        )}
      </main>

      <footer className="flex h-16 items-center justify-end">
        <FormActions
          steps={bulkUploadEntity?.steps}
          selectedStep={bulkUploadEntity?.selectedStep}
          setPrevStep={setPrevStep}
          setNextStep={verifyNextSteps}
          showSubmit={showSubmit}
          onSubmit={bulkUploadEntity?.startValidation}
          onCancel={onCancelForm}
          onClose={onClose}
        />
      </footer>
    </div>
  );
};

const Navbar = ({ steps }) => {
  const visibleSteps = steps.filter((item) => item.visible);
  return (
    <nav className="flex w-524 space-x-2">
      {visibleSteps.map((step, index) => {
        const activeStep = step.status === "current";
        return (
          <div className="flex flex-col items-center" key={index}>
            <span
              className={`mb-1 h-2.5 w-32 rounded-md ${
                activeStep ? "bg-blueBackground" : "bg-lightBlueBackground"
              }`}></span>
            <p
              className={`text-sm decoration-solid ${
                activeStep ? "text-textDarkGray" : "text-textGray"
              }`}>
              {step.name}
            </p>
          </div>
        );
      })}
    </nav>
  );
};

const FormActions = ({
  steps,
  setPrevStep,
  setNextStep,
  showSubmit,
  onSubmit,
  onCancel,
}) => {
  const bulkUploadEntity = useContext(ModalContext);
  return (
    <>
      <div className="flex space-x-4">
        <button
          className={`mr-2 cursor-pointer py-3 text-base font-semibold text-primaryAccent underline`}
          onClick={onCancel}>
          Cancel
        </button>
        <button
          onClick={setPrevStep}
          className={`cursor-pointer rounded-md border px-6 py-3 text-base font-semibold ${
            bulkUploadEntity?.selectedStep?.id === steps[0]?.id
              ? "cursor-not-allowed border-mediumGray text-mediumGray"
              : "cursor-pointer border-primaryAccent text-primaryAccent"
          }`}>
          Previous
        </button>
        {!showSubmit && (
          <button
            onClick={setNextStep}
            disabled={
              !(
                bulkUploadEntity?.selectedStep &&
                bulkUploadEntity?.selectedStep["isVerify"]
              )
            }
            className={`rounded-md px-6 py-3 text-base font-semibold ${
              !(
                bulkUploadEntity?.selectedStep &&
                bulkUploadEntity?.selectedStep["isVerify"]
              )
                ? "cursor-not-allowed border border-mediumGray text-mediumGray"
                : "cursor-pointer bg-primaryAccent text-white"
            }`}>
            Next
          </button>
        )}
        {showSubmit && (
          <button
            onClick={bulkUploadEntity?.reason ? onSubmit : undefined}
            className={`cursor-pointer rounded-md px-6 py-3 text-base font-semibold ${
              bulkUploadEntity?.reason
                ? "cursor-pointer bg-primaryAccent text-white"
                : "cursor-not-allowed border border-mediumGray text-mediumGray"
            }`}>
            Submit
          </button>
        )}
      </div>
    </>
  );
};

const FileUpload = ({ dashboardFields, BULK_UPLOAD_DUPLICATE_VALIDATION }) => {
  const bulkUploadEntity = useContext(ModalContext);

  const fileInputRef = useRef(null);
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);

  const handleFileChange = async (file) => {
    if (file && file?.type === bulkUploadEntity?.bulkUploadFileContentType) {
      appState.setLoading();
      const selectedFileReadResponse = await readFileDetails(file);
      appState.removeLoading();
      if (
        Math.round(file?.size / FILE_SIZE_1_MB_VALIDATION) >
        FILE_SIZE_25_MB_VALIDATION
      ) {
        notify.error("File size is large or exceed than 25 mb");
      } else if (
        selectedFileReadResponse &&
        selectedFileReadResponse?.data &&
        selectedFileReadResponse?.data?.length > FILE_ROWS_VALIDATION_10000
      ) {
        notify.error("File data is exceed than 10000 rows");
      } else {
        bulkUploadEntity?.setTableDetails(selectedFileReadResponse?.data);
        bulkUploadEntity?.setSelectedFileDetails(file, dashboardFields);
      }
    } else {
      notify.error("Invalid file.");
    }
  };

  const VerifyFileDetails = async () => {
    if (!bulkUploadEntity?.selectedFile) return;
    try {
      appState.setLoading();
      if (
        bulkUploadEntity?.tableRows &&
        bulkUploadEntity?.tableHeaders &&
        bulkUploadEntity?.tableRows?.length !== 0 &&
        bulkUploadEntity?.tableHeaders?.length !== 0
      ) {
        const fileErrorList = validateFileUploadData(
          bulkUploadEntity?.tableRows,
          bulkUploadEntity?.tableHeaders,
        );
        if (fileErrorList && fileErrorList?.length !== 0) {
          bulkUploadEntity?.setErrors(fileErrorList);
        } else {
          if (
            !bulkUploadEntity?.selectedFile ||
            !bulkUploadEntity?.s3PresignedURL ||
            !bulkUploadEntity?.s3FileName
          )
            return;
          let formedSteps = bulkUploadEntity?.steps;
          if (formedSteps && formedSteps?.length !== 0) {
            formedSteps[0] = {
              ...formedSteps[0],
              status: "current",
              isVerify: true,
            };

            bulkUploadEntity?.setSteps(formedSteps);
            bulkUploadEntity?.setSelectedStep(formedSteps[0]);
          }
        }
      }
      appState.removeLoading();
    } catch (error) {
      console.log(error);
      appState.removeLoading();
      notify.error("An error occurred while processing the file.");
    }
  };

  const validateFileUploadData = (fileDataList, fileHeaders) => {
    let validateDuplicateValuesInColumn = BULK_UPLOAD_DUPLICATE_VALIDATION;
    let errorList = [];
    let columnData = {};
    validateDuplicateValuesInColumn = validateDuplicateValuesInColumn?.map(
      (matchColumn) => fileHeaders.indexOf(matchColumn),
    );
    validateDuplicateValuesInColumn.forEach((column) => {
      columnData[column] = new Set();
    });
    fileDataList.forEach((row, rowIndex) => {
      Object.keys(row).forEach((column) => {
        const cellValue = row[column].trim();
        if (!cellValue) {
          errorList.push({
            type: "Blank Data Entry",
            message: `<span style="font-weight: 600">${fileHeaders[parseInt(column)]}</span> is missing at row ${rowIndex + 1}`,
          });
        }
        if (validateDuplicateValuesInColumn.includes(parseInt(column))) {
          if (columnData[column].has(cellValue)) {
            errorList.push({
              type: "Duplicate Data Entry",
              message: `<span style="font-weight: 600">${fileHeaders[parseInt(column)]}</span> is duplicate at row ${rowIndex + 1}`,
            });
          } else {
            columnData[column].add(cellValue);
          }
        }
      });
    });
    return errorList;
  };

  const readFileDetails = async (file) => {
    const fileReadResponse = await new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: resolve,
        error: reject,
      });
    });
    return fileReadResponse;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const resetUploadFile = () => {
    let formedSteps = bulkUploadEntity?.steps;
    if (formedSteps && formedSteps?.length !== 0) {
      formedSteps[0] = {
        ...formedSteps[0],
        status: "current",
        isVerify: false,
      };

      bulkUploadEntity?.setSteps(formedSteps);
      bulkUploadEntity?.setSelectedStep(formedSteps[0]);
    }
    bulkUploadEntity?.setToStartFileUploadOnS3({
      startFileUpload: false,
      fileUploadedOnS3: false,
    });
    bulkUploadEntity?.setTableDetails(null);
    bulkUploadEntity?.setSelectedFileDetails(null);
    bulkUploadEntity?.setErrors(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div className="mx-auto max-w-3xl p-4">
      <div
        className="mb-4 rounded-md border-2 border-dashed border-gray-300 bg-gray-100 p-4 text-center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        <label
          htmlFor="file-input"
          className="flex cursor-pointer flex-col items-center">
          <div className="mb-2 h-10 w-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#224E73"
              className="h-10 w-10">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
          </div>
          <span className="text-gray-600">
            <span className="font-semibold">
              Drag &amp; drop files or{" "}
              <span
                className="text-[#224E73] underline"
                onClick={(e) => {
                  e.preventDefault();
                  resetUploadFile();
                  fileInputRef.current.click();
                }}>
                Browse
              </span>
            </span>
            <br />
            <span className="text-gray-400">
              Supported format:{" "}
              <span>CSV (Max. 25MB OR 10000 Rows accepted)</span>
            </span>
          </span>
        </label>
        <input
          ref={fileInputRef}
          id="file-input"
          type="file"
          accept=".csv"
          onChange={(e) => handleFileChange(e.target.files[0])}
          className="hidden"
        />
      </div>

      {bulkUploadEntity?.selectedFile && (
        <div className="mb-4 flex items-center justify-start gap-4">
          <div className="bottom-1 flex w-2/3 items-center justify-between border-2 px-3 py-2">
            <p className="text-gray-600">
              {bulkUploadEntity?.selectedFile?.name}
            </p>
            <XCircleIcon
              onClick={resetUploadFile}
              className="bg-white-800 h-6 w-6 cursor-pointer text-gray-400"
            />
          </div>

          {!bulkUploadEntity?.error && (
            <button
              className={`mr-2 py-3 font-semibold text-primaryAccent underline ${bulkUploadEntity?.selectedStep && bulkUploadEntity?.selectedStep?.isVerify ? "cursor-default" : "cursor-pointer"}`}
              onClick={
                !(
                  bulkUploadEntity?.selectedStep &&
                  bulkUploadEntity?.selectedStep?.isVerify
                )
                  ? VerifyFileDetails
                  : undefined
              }>
              {bulkUploadEntity?.selectedStep &&
              bulkUploadEntity?.selectedStep?.isVerify
                ? "Verified!"
                : "Verify!"}
            </button>
          )}

          {bulkUploadEntity?.error && bulkUploadEntity?.error.length > 0 && (
            <button
              className="mr-2 cursor-pointer py-3 font-semibold text-primaryAccent underline"
              onClick={(e) => {
                e.preventDefault();
                resetUploadFile();
                fileInputRef.current.click();
              }}>
              Re-Upload!
            </button>
          )}
        </div>
      )}

      {bulkUploadEntity?.selectedStep &&
        bulkUploadEntity?.selectedStep?.isVerify && (
          <SuccessNotification
            message={`Your file ${bulkUploadEntity?.selectedFile && bulkUploadEntity?.selectedFile?.name} is verified successfully.`}
          />
        )}
      {bulkUploadEntity?.error && bulkUploadEntity?.error.length > 0 && (
        <ErrorNotification error={bulkUploadEntity?.error} />
      )}
    </div>
  );
};

const MapFields = ({}) => {
  const bulkUploadEntity = useContext(ModalContext);
  return (
    <div className="mx-auto ml-0 mr-0 max-w-3xl p-4">
      <div className="mb-5 w-full">
        <div className="mb-2 flex items-center">
          <div className="text-base text-gray-500">Name Of The File</div>
        </div>
        <div className="mt-1 block w-full cursor-not-allowed rounded-md border border-gray-300 bg-white px-3 py-2 font-light shadow-sm sm:text-sm">
          {bulkUploadEntity?.selectedFile?.name}
        </div>
      </div>
      <div className="mb-5 w-full">
        <div className="mb-2 flex items-center">
          <div className="text-base text-gray-500">Number Of Entries</div>
        </div>
        <div className="mt-1 block w-14 rounded-full bg-green-100 px-3 py-2 text-center font-semibold text-green-600 shadow-sm sm:text-sm">
          {bulkUploadEntity?.tableRows && bulkUploadEntity?.tableRows?.length}
        </div>
      </div>
      <div className="mb-5 w-full">
        <div className="mb-2 flex items-center">
          <div className="text-base text-gray-500">Mapped Field Headers</div>
        </div>
        <div className="flex w-full flex-row items-start justify-between">
          {bulkUploadEntity?.tableHeaders &&
            bulkUploadEntity?.tableHeaders?.length !== 0 && (
              <div className="mx-4 flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  {bulkUploadEntity?.tableHeaders.map((header, index) => (
                    <div className="mb-4 flex items-center p-2" key={index}>
                      <Tooltip title={header} placement="topLeft">
                        <div className="w-56 truncate text-base text-gray-500">
                          {header}
                        </div>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            )}

          <Dropdown
            title="Dashboard Columns"
            list={bulkUploadEntity?.fields}
            onChange={(value, index) => {
              bulkUploadEntity?.changeselectedValue(value, index);
            }}
            value={bulkUploadEntity?.defaultValue}
            selectlist={bulkUploadEntity?.attributes}
          />
        </div>
        {bulkUploadEntity?.error && <Error props={bulkUploadEntity?.error} />}
      </div>
      <div className="mb-5 w-full">
        <TextArea
          label={
            <span>
              {capitalizeText(
                `Reason for ${bulkUploadEntity?.entityType} Adjustment`,
              )}{" "}
              <span className="text-red-500">*</span>
            </span>
          }
          placeholder="Please provide a reason."
          onChange={(event) => {
            bulkUploadEntity?.setReason(event?.target?.value);
          }}
          error=""
          parentClasses="mb-4"
          labelClasses="text-base text-gray-500"
          rows={4}
          value={bulkUploadEntity?.reason}
        />
      </div>
    </div>
  );
};

const ErrorNotification = ({ error }) => {
  return (
    <div className="flex justify-start space-x-2 rounded-md border border-red-400 bg-red-100 px-4 py-3 text-red-700">
      <div className="h-auto w-7 flex-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-6 w-6 text-red-500">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
          />
        </svg>
      </div>
      <div className="h-auto grow">
        <div className="flex flex-col">
          <p className="font-semibold">Errors</p>
          <p className="">You can make the changes and try to upload again!</p>
        </div>
        <ul className="mt-2 list-outside list-disc space-y-2 pl-5">
          {error.map((error, index) => (
            <li key={index}>
              <span dangerouslySetInnerHTML={{ __html: error?.message }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SuccessNotification = ({ message }) => {
  return (
    <div className="flex items-center justify-start">
      <div className="flex flex-row space-x-2 border-green-100 bg-green-100 px-4 py-3 text-green-700">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-6 w-6 text-green-500" />
        </div>
        <p>{message}</p>
      </div>
    </div>
  );
};

const Dropdown = ({ title, list, onChange, value, selectlist, disabled }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (list && list?.length !== 0) {
      setOptions(
        list.map((option) => {
          return {
            name: option,
            label: option,
          };
        }),
      );
    }
  }, [list]);

  return (
    <div className="mx-4 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center space-y-2">
        {selectlist.map((_, i) => {
          return (
            <AutocompleteSingleSelectDropdown
              key={i}
              options={options}
              labelKey={"label"}
              valueKey={"name"}
              onChange={(value) => onChange(value, i)}
              value={value[i]}
              placeholder=""
              showCheckedIndicator={false}
              sortOptions={false}
              textNormal="!text-sm !text-gray-500"
            />
          );
        })}
      </div>
    </div>
  );
};

const capitalizeText = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default UploadBulk;
