import withWarehousesLogic from "#components/HOC/withWarehousesLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import WarehousesList from "#components/warehouses/WarehousesList";
import WarehouseForm from "#components/warehouses/WarehouseForm";
import { UserAddIcon } from "@heroicons/react/outline";
import BulkUpload from "../components/bulkUpload/UploadBulk";
import { ModalProvider } from "../components/bulkUpload/useReducer";
import ConfirmationPopUp from "#components/common/ConfirmationPopUp";
import Popover from "#components/utils/Popover";
import BulkUploadSlideOverModal from "#newUiComponents/commons/bulkUploadV2/BulkUploadSlideOverModal";
import { ACTION_TYPES } from "#newUiComponents/commons/bulkUploadV2/useReducer";
import { BULK_UPLOAD_DUPLICATE_VALIDATION } from "#components/HOC/withWarehousesLogic";
import ExportBanner, {
  BANNER_TYPE_ENUM,
} from "#newUiComponents/commons/ExportBanner";

const Warehouses = ({
  newCreatedWarehouseId,
  setNewCreatedWarehouseId,
  confirmationVisible,
  setConfirmationVisible,
  updateConfirmation,
  setUpdateConfirmation,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  fetchWarehouse,
  saveWarehouse,
  deleteButtonClicked,
  onChange,
  onChangeAdress,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  total,
  pageNumber,
  checkPagination,
  perPage,
  setPerPage,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
  handleManageActionClick,
  showBulkUpload,
  setShowBulkUpload,
  selectedActionType,
  bulkUploadV2Enabled,
  pastUploadsList,
  showBanner,
  typeOfBanner,
  bulkuploadFileName,
  closeBanner,
  setSelectedActionType,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Warehouse Management</PageTitle>
      </div>

      {!bulkUploadV2Enabled && (
        <div className="mb-4 flex items-center justify-end space-x-2">
          <AddButton
            text="Add Warehouse"
            onClick={() => setSelectedWarehouse({ active: true })}
            icon={UserAddIcon}
            disabled={!writable}
          />

          <AddButton
            text="Download Template"
            onClick={() =>
              (window.location =
                "https://templates-onboarding.s3.amazonaws.com/uat/Warehouses.xlsx")
            }
            disabled={!writable}
          />

          <BulkUpload
            dashboardFields={dashboardFields}
            saveBulkUpload={(rows) => saveBulkUpload(rows)}
            errorMessage={errorMessage}
            successMessage={successMessage}
            validate={validate}
            validationResult={validationResult}
          />
        </div>
      )}

      {bulkUploadV2Enabled && (
        <div className="mb-4 flex items-center justify-end space-x-2">
          {ACTION_TYPES && (
            <Popover
              title={"Manage Actions"}
              showChevron={true}
              disabled={!writable}
              panelClassName="mt-2 bg-bgWhite z-10 
          overflow-auto rounded-lg p-1 
          border border-borderGray w-[14rem]"
              showOverlay={true}>
              {ACTION_TYPES.map((action) => {
                return (
                  <div
                    key={action.name}
                    className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                    onClick={() => handleManageActionClick(action.value)}>
                    {action.name}
                  </div>
                );
              })}
            </Popover>
          )}
        </div>
      )}
    </div>

    {showBanner && (
      <ExportBanner
        content={
          typeOfBanner === BANNER_TYPE_ENUM?.INITIATED ? (
            <span className="pb-2">
              Upload Scheduled! Your file {bulkuploadFileName} has been
              scheduled for upload. You will be notified when it is ready.
            </span>
          ) : typeOfBanner === BANNER_TYPE_ENUM?.COMPLETED ? (
            <span className="pb-2">
              Upload Successful! Your file {bulkuploadFileName} has been
              uploaded. You can check{" "}
              <span
                className="cursor-pointer underline"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageActionClick("PAST_UPLOADS");
                }}>
                Past Uploads
              </span>{" "}
              for further information.
            </span>
          ) : typeOfBanner === BANNER_TYPE_ENUM?.FAILED ? (
            <span className="pb-2">
              Upload Failed! Your file {bulkuploadFileName} couldn't be
              uploaded. You can check{" "}
              <span
                className="cursor-pointer underline"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageActionClick("PAST_UPLOADS");
                }}>
                Past Uploads
              </span>{" "}
              for further information.
            </span>
          ) : typeOfBanner === BANNER_TYPE_ENUM?.PARTIAL_COMPLETE ? (
            <span className="pb-2">
              Uploaded Partially! Your file {bulkuploadFileName} has been
              uploaded partially. You can check{" "}
              <span
                className="cursor-pointer underline"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageActionClick("PAST_UPLOADS");
                }}>
                Past Uploads
              </span>{" "}
              for further information.
            </span>
          ) : null
        }
        typeOfBanner={typeOfBanner}
        onClose={closeBanner}
      />
    )}

    <WarehousesList
      onChangeSearchKeyword={onChangeSearchKeyword}
      filters={filters}
      submitFilters={submitFilters}
      clearFilters={clearFilters}
      setSort={setSort}
      sort={sort}
      clearKeyword={clearKeyword}
      setShowFilters={setShowFilters}
      warehouses={warehouses}
      editButtonClicked={(e) => {
        fetchWarehouse(e.id);
      }}
      deleteButtonClicked={(e) => deleteButtonClicked(e)}
      headers={[
        "Name",
        "Code",
        "Type of Warehouse",
        "Status",
        "Last Updated",
        "Action",
      ]}
      noValuesText="No Warehouses"
      writable={writable}
      total={total}
      pageNumber={pageNumber}
      checkPagination={checkPagination}
      perPage={perPage}
      setPerPage={setPerPage}
    />

    {selectedWarehouse && (
      <WarehouseForm
        title={selectedWarehouse.id ? "Edit Warehouse" : "Add Warehouse"}
        selectedWarehouse={selectedWarehouse}
        onChange={onChange}
        onChangeAdress={onChangeAdress}
        onClose={() => setSelectedWarehouse()}
        onSubmit={() => saveWarehouse(selectedWarehouse)}
        warehouses={warehouses}
        setSelectedWarehouse={setSelectedWarehouse}
      />
    )}

    {confirmationVisible && !updateConfirmation && (
      <ConfirmationPopUp
        message={
          <span>
            New Warehouse :{" "}
            <span style={{ color: "#CA3A31", fontWeight: "bold" }}>
              {newCreatedWarehouseId}
            </span>{" "}
            has been successfully created.
          </span>
        }
        context="Context goes here."
        onClose={() => {
          setConfirmationVisible(false);
          setNewCreatedWarehouseId(null);
        }}
      />
    )}

    {confirmationVisible && updateConfirmation && (
      <ConfirmationPopUp
        message={
          <span>
            Warehouse :{" "}
            <span style={{ color: "#CA3A31", fontWeight: "bold" }}>
              {newCreatedWarehouseId}
            </span>{" "}
            has been successfully updated.
          </span>
        }
        context="Context goes here."
        onClose={() => {
          setConfirmationVisible(false);
          setNewCreatedWarehouseId(null);
          setUpdateConfirmation(false);
        }}
      />
    )}

    {showBulkUpload && (
      <BulkUploadSlideOverModal
        dashboardFields={dashboardFields}
        showBulkUpload={showBulkUpload}
        setShowBulkUpload={setShowBulkUpload}
        selectedActionType={selectedActionType}
        setSelectedActionType={setSelectedActionType}
        BULK_UPLOAD_DUPLICATE_VALIDATION={BULK_UPLOAD_DUPLICATE_VALIDATION}
        pastUploadsList={pastUploadsList}
      />
    )}
  </div>
);

export default withWarehousesLogic(Warehouses);
